import { useContext, useEffect, useState } from 'react';
import { apiExternal, IOmieSettings, IRefreshToken, IUserAuth, IUserJwt } from 'services';
import { stage, isLocalhost, stageOmie } from 'services';
import { initTracker, LocalStorageService, Logger } from 'services';
import LoadingSpinner from 'components/LoadingSpinner';
import { getMyApps, getUserSession } from 'resources';
import { GetUserSession } from 'interfaces/api';
import { initConpass } from 'utils/conpass';
import { IProviderAuth, UserContext } from 'context/UserContext';
import { apiOmie } from 'services/api/omie';
import { base64ToString } from 'utils/converts';
import { MyApps } from 'interfaces';
import { getSHA256Hash } from 'utils/crypto';

interface IGetOmieSettings {
  body: string;
  ts: number;
}

export const getNewToken = async (
  token: IUserJwt['token'],
  refreshToken: IUserJwt['refresh_token'],
  onErrorForceLoginPage = true,
): Promise<void> => {
  try {
    const url = '/users/refresh-token/';
    const payload = {
      token: token,
      refresh_token: refreshToken,
    };
    const response = await apiOmie.post<IRefreshToken>(url, payload);

    if (response.data) {
      Logger.debug(response.data);
      if (response.data.token) {
        LocalStorageService.setToken(response.data.token);
      }
      if (response.data.refresh_token) {
        LocalStorageService.setRefreshToken(response.data.refresh_token);
      }
    } else {
      Logger.error('Não foi possível capturar o novo token');
      Logger.error(response);
      LocalStorageService.clearStorage();
    }
  } catch (e) {
    Logger.error('Não foi possível capturar o novo token');
    Logger.error(e);
    LocalStorageService.clearStorage();
    if (onErrorForceLoginPage) {
      if (!isLocalhost) {
        const currentPage = window.location.href;
        const urlLogout = `${stageOmie}/login/?redirect_to=${currentPage}`;
        window.location.href = urlLogout;
      }
    }
  }
};

const useProviderAuth = (): IProviderAuth => {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState<IUserAuth | null>(null);

  const logoutUser = (): void => {
    LocalStorageService.clearStorage();
    setUser(null);
    Logger.info('Logout realizado com sucesso');
  };

  useEffect(() => {
    (async (): Promise<void> => {
      async function getSession(): Promise<void> {
        const session = LocalStorageService.getSessionToken();
        if (!session) {
          Logger.info('Buscando session para o usuário');

          const response = await getUserSession();
          if (response.status === 'success') {
            LocalStorageService.setSessionToken((response as GetUserSession).id);
          } else {
            Logger.error('Não foi possível buscar uma sessão para o usuário');
          }
        } else {
          Logger.info('Session encontrada no storage');
        }
      }

      async function getMyAppsAuth(): Promise<MyApps[] | null> {
        const userStorage = LocalStorageService.getUser();
        if (userStorage?.email === base64ToString('YWp1ZGFAb21pZS5jb20uYnI=')) return null;

        try {
          const response = await getMyApps();
          if (response.status === 'fatal_error') return null;
          return response.data as MyApps[];
        } catch (e) {
          Logger.error(e);
          return null;
        }
      }

      async function getGravatar(email: string): Promise<string | null> {
        try {
          const hash = await getSHA256Hash(email);
          const url = `https://www.gravatar.com/avatar/${hash}?s=50&d=mp`;
          return url;
        } catch (e) {
          Logger.error(e);
          return null;
        }
      }

      async function getOmieSettings(): Promise<IOmieSettings | null> {
        try {
          const url = '/users/me/omie-settings';
          const response = await apiOmie.get<IGetOmieSettings>(url);
          const responseClean = base64ToString(response.data.body);
          const omieSettings: IOmieSettings = JSON.parse(responseClean);
          Logger.debug(omieSettings);
          return omieSettings;
        } catch (e) {
          Logger.error(e);
          return null;
        }
      }

      function userIsCustomer(myApps: MyApps[] | null): boolean {
        if (myApps === null) return true;
        if (myApps.length > 0) return true;

        // usuario ajuda@omie
        const userStorage = LocalStorageService.getUser();
        if (userStorage?.email === base64ToString('YWp1ZGFAb21pZS5jb20uYnI=')) return true;

        return false;
      }

      async function handleUser(jwtData: IUserJwt): Promise<void> {
        LocalStorageService.setToken(jwtData.token);
        LocalStorageService.setRefreshToken(jwtData.refresh_token);

        const [myApps, settings, gravatar] = await Promise.all([
          getMyAppsAuth(),
          getOmieSettings(),
          getGravatar(jwtData.email),
        ]);
        const isCustomer = userIsCustomer(myApps);

        Logger.debug('User isCustomer:', isCustomer);

        const userLocal = {
          ...jwtData,
          myApps: myApps === null ? 1 : myApps.length,
          settings: settings,
          isCustomer: isCustomer,
          avatar: gravatar,
        } as IUserAuth;

        LocalStorageService.setUser(userLocal);
        setUser(userLocal);
      }

      async function findUser(): Promise<void> {
        const url = `${stageOmie}/api/portal/users/me/token`;
        Logger.info(`Buscando dados do usuário em ${url}`);
        await apiExternal
          .get<IUserJwt>(url, { withCredentials: true })
          .then(async (res) => {
            await handleUser(res.data);
          })
          .catch(async () => {
            Logger.warning(`Usuário não autenticado em ${stageOmie}`);
            if (isLocalhost) {
              const userMock = ['prod', 'release'].includes(stage)
                ? await import('mocks/user-prod.json')
                : await import('mocks/user-dsv.json');
              if (userMock) {
                Logger.warning('Usando credenciais mockadas', userMock);
                await handleUser({
                  ...userMock,
                  isCustomer: true,
                  myApps: 1,
                  app_ver: 2,
                });
              }
            }
          });
      }

      await findUser();
      await getSession();
      await initTracker();

      Logger.divisor();

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    isAuthenticated: user !== null,
    user,
    logoutUser,
    getNewToken,
  };
};

export function ProviderAuth({ children }: { children: JSX.Element }): JSX.Element {
  const auth = useProviderAuth();

  if (auth.isLoading) {
    Logger.debug('Aguardando useProviderAuth');
    return <LoadingSpinner fullScreen />;
  }

  async function handleConpass(): Promise<void> {
    if (!isLocalhost && ['prod', 'release'].includes(stage)) {
      Logger.debug('[CONPASS]', 'Iniciando');
      if (auth.user && auth.user.settings !== null) {
        Logger.debug('[CONPASS]', `Usuário ${auth.user.settings.conpass}`);
        const conpassObj = {
          ...auth.user.settings.conpass,
        };
        conpassObj.custom_fields.isAnonymous = false;
        initConpass(conpassObj);
      } else {
        const sessionToken = LocalStorageService.getSessionToken();
        if (sessionToken) {
          Logger.debug('[CONPASS]', 'Usuário anônimo');
          // anônimo
          initConpass({
            email: sessionToken,
            custom_fields: {
              sessionId: sessionToken,
              origin: 'portal',
              isAnonymous: true,
            },
          });
        } else {
          Logger.debug('[CONPASS]', 'Usuário sem sessão');
        }
      }
    } else {
      Logger.debug('[CONPASS]', 'Ambiente de testes, Conpass nao será instanciada');
    }
  }

  Logger.debug('ProviderAuth finalizado', auth);

  handleConpass();

  return <UserContext.Provider value={auth}>{children}</UserContext.Provider>;
}

const useAuth = (): IProviderAuth => useContext(UserContext);

export default useAuth;
